import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import style from "../styles/about.module.css"

const about = () => {
  return (
    <Layout>
      <SEO title="about" />
      <p className={style.p}>
        Verantwortlich für den Inhalt dieser Seite: <br></br>
        Manuela Wurm <br></br>Sonnenstraße 18<br></br>3322 Viehdorf<br></br>
      </p>
      <p className={style.p}>
        E-Mail: wurm.manuela@gmx.at <br></br>
        Telefon: 0043 650 41 77 113
      </p>
      <p className={style.p}>
        Meine Termine enthalten eine auf Mikronährstoffe und Probiotika bezogene
        Produktberatung und sind keine medizinische beziehungsweise
        medizinisch-technische Leistung und ersetzt diese auch nicht. Die
        gelieferten Informationen ersetzen keinesfalls eine medizinische
        Behandlung durch den Arzt bzw. Ernährungsberatung durch Diätologen und
        dürfen auch nicht als eine solche verstanden werden. Ebenso stellen
        diese keine medizinischen Ratschläge dar und ersetzen keinesfalls die
        medizinische Diagnose, Beratung und Behandlung durch den Arzt und dürfen
        daher auch keinesfalls als solche verstanden werden.
      </p>

      <h2>Haftung</h2>
      <p className={style.p}>
        Wir möchten darauf hinweisen, dass wir keinen Einfluss auf die
        Gestaltung und den Inhalt der Seiten haben, auf die verlinkt wird. Wir
        können daher auch keine Gewähr für die Aktualität, Korrektheit,
        Vollständigkeit oder Qualität der dort bereitgestellten Informationen
        übernehmen.
      </p>

      <h2>URHEBERRECHT & BILDNACHWEISE</h2>

      <p className={style.p}>
        Alle Texte, Bilder, Grafiken, Ton-, Video- und Animationsdateien sowie
        ihre Arrangements unterliegen dem Urheberrecht und anderen Gesetzen zum
        Schutz geistigen Eigentums. Sie dürfen weder für Handelszwecke oder zur
        Weitergabe kopiert, noch verändert und auf anderen Websites verwendet
        werden. Einige Internetseiten enthalten auch Material, das dem
        Urheberrecht derjenigen unterliegt, die dieses zur Verfügung gestellt
        haben. Alle verwendeten fremden Inhalte sind lizenzrechtlich
        abgesichert.
      </p>

      <p className={style.p}>
        Alle verwendeten Bilder sind lizenzfreie Bilder von{" "}
        <a href="www.pexels.com">www.pexels.com</a> ,{" "}
        <a href="www.unsplash.com">www.unsplash.com</a>
        und <a href="www.pixabay.com">www.pixabay.com</a> oder mit freundlicher
        Genehmigung von <a href="www.channoine.at">Channoine Cosmetics AG</a>.
        Eine Vervielfältigung oder Verwendung der Grafiken ist nicht gestattet.
        Weitere Fotos auf der Website sind entsprechend der Urheberrechte
        markiert.
      </p>

      <p className={style.p}>
        <strong>Keine Abmahnung ohne vorherigen Kontakt.</strong>
        <br></br>
        Sollte der Inhalt oder die Aufmachung dieser Seiten Rechte Dritter oder
        gesetzliche Bestimmungen verletzen, bitten wir um eine entsprechende
        Nachricht ohne Kostennote.
      </p>
    </Layout>
  )
}

export default about
